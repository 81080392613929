<template>
  <div>
    <title>SIM INVENTORY ~ ADD SCHEDULE BUYER LIST</title>
    <section class="content-header">
      <h1>
        Add Schedule Buyer List
        <br />
        <h4>Please enter transaction data for the schedule buyer list</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Transaksi Daftar Schedule Buyer</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Add Schedule Buyer List</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Order Number </label>
                    <input
                      type="text"
                      v-model="isidata.ordernum"
                      autocomplete="off"
                      class="form-control"
                    />
                    <input
                      type="hidden"
                      readonly
                      v-model="isidata.idxprosesprodukd"
                      autocomplete="off"
                      class="form-control"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getordernumberbuyer()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />

                <div class="form-group">
                  <label for="exampleInputEmail1">Order Date </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.dateord"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Start Date </label>
                  <input
                    type="date"
                    v-model="isidata.datestart"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">End Date</label>
                  <input
                    type="date"
                    v-model="isidata.dateend"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes </label>
                  <input
                    type="text"
                    v-model="isidata.notesbs"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                ><i class="fa fa-save"></i>
                  Simpan
                </button>
                &nbsp;
                <router-link to="/produk">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <div class="box box-default">
            <div class="box-header with-border">
              <i class="fa fa-bullhorn"></i>
              <h3 class="box-title">Detail Data</h3>
            </div>
            <div class="box-body">
              <div class="form-group">
                <label for="exampleInputEmail1">Batch Code </label>
                <input
                  type="text"
                  readonly
                  v-model="isidata.noprdk"
                  autocomplete="off"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Product Name </label>
                <input
                  type="text"
                  readonly
                  v-model="isidata.nmprdk"
                  autocomplete="off"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Process Name </label>
                <input
                  type="text"
                  readonly
                  v-model="isidata.nnmpros"
                  autocomplete="off"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Copyright </label>
                <input
                  type="text"
                  readonly
                  v-model="isidata.haki"
                  autocomplete="off"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Size </label>
                <input
                  type="text"
                  readonly
                  v-model="isidata.sizep"
                  autocomplete="off"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      loading: false,
      isidata: {
        ordernum: "",
        idxprosesprodukd: "",
        noprdk: "",
        nmprdk: "",
        nnmpros: "",
        sizep: "",
        haki: "",
        dateord: "",
        datestart: "",
        dateend: "",
        notesbs: ""
      }
    };
  },
  created() {
    this.isidata.idxprosesprodukd = this.$route.params.id;
    this.fetchdetail()
  },
  methods: {
    getordernumberbuyer() {
       this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      const urlAPIfetchDatadetail =
        this.$apiurl +
        "buyer_order/getbuyer_orderbyorder_number?order_number=" +
        this.isidata.ordernum;
      axios
        .get(urlAPIfetchDatadetail, { headers })
        .then((resp) => {
          
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Data not found",
              showConfirmButton: false
            });
            this.isidata.dateord = ""
          } else {
            this.isidata.dateord = resp.data.data.tanggal_order;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchdetail() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      // const urlAPISaveDatadetail = "http://26.183.23.191/inventory/backend/inventory/api/produk_d_proses/getproduk_d_prosesbyid_produk_d_proses_full_join?id_produk_d_proses="+this.isidata.idxprosesprodukd;
      const urlAPIfetchDatadetail =
        this.$apiurl +
        "produk_d_proses/getproduk_d_prosesbyid_produk_d_proses_full_join?id_produk_d_proses=" +
        this.isidata.idxprosesprodukd;
      axios
        .get(urlAPIfetchDatadetail, { headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Data not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.noprdk = resp.data.data.nomor_produk;
            this.isidata.nmprdk = resp.data.data.nama_produk;
            this.isidata.nnmpros = resp.data.data.nama_proses;
            this.isidata.sizep = resp.data.data.size;
            this.isidata.haki = resp.data.data.copyright;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async saveData() {
      this.loading = true;
      var noord = this.isidata.ordernum;
      var idxprosprodukd = this.isidata.idxprosesprodukd;
      var tglprosprodukd = this.isidata.dateord;
      var tglstart = this.isidata.datestart;
      var tglselesai = this.isidata.dateend;
      var catatanbs = this.isidata.notesbs;
      if (noord == "" || idxprosprodukd == "" || tglprosprodukd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Order Number / Order Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglstart == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Date Start can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglselesai == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Date End can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatanbs == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatadetail = {
          order_number: this.isidata.ordernum,
          id_produk_d_proses: this.isidata.idxprosesprodukd,
          date_start: this.isidata.datestart,
          date_end: this.isidata.dateend,
          notes: this.isidata.notesbs,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        // const urlAPISaveDatadetail = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order_schedule_proses/savebuyer_order_schedule_proses";
        const urlAPISaveDatadetail =
          this.$apiurl +
          "buyer_order_schedule_proses/savebuyer_order_schedule_proses";
        axios
          .post(urlAPISaveDatadetail, paramdatadetail, { headers })
          .then((respn) => {
            if (respn.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data Successfully",
                showConfirmButton: false
              });
              this.$router.push({ name: "buyerorder" });
              this.loading = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data Failed",
                showConfirmButton: false
              });
              this.$router.push({ name: "buyerorder" });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
